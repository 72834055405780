<template>
  <div id="forgot-password">
    <component
      :is="component"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'ForgotPassword',
  components: {
    RequestCode: lazyLoad('views/ForgotPassword/RequestCode'),
    SubmitCode: lazyLoad('views/ForgotPassword/SubmitCode'),
  },
  created() {
    if (this.$route.query.email) {
      this.setEmail(this.$route.query.email);
    }
  },
  computed: {
    ...mapGetters('forgotPassword', [
      'data',
    ]),
    component() {
      if (!this.data.email) {
        return 'RequestCode';
      }
      return 'SubmitCode';
    },
  },
  methods: {
    ...mapActions('forgotPassword', [
      'setEmail',
    ]),
  },
};
</script>
